<template>
  <PageTemplate>
		<Section>
			<Stack>
				<Images height="500px" width="25%" image="theme-sd/services3.jpg" />
				<Images height="500px" width="25%" image="theme-sd/services4.jpg" />
				<Images height="500px" width="25%" image="theme-sd/services1.jpg" />
				<Images height="500px" width="25%" image="theme-sd/services2.jpg" />
			</Stack>
			<Card size="large">
				<CardSection>
					<Stack direction="column" space="3">
						<Heading size="1" align="center">our services.</Heading>
						<Heading size="4" align="center">WE OFFER THREE DIFFERENT PACKAGES TO FIT YOUR NEEDS BEST.</Heading>
						<Paragraph align="center">Looking for more? Reach out! We're always happy to collaborate!</Paragraph>
						<Button label="Contact Us" color="primary" size="large" url="/sedona/contact" />
					</Stack>
				</CardSection>
			</Card>
			<Card size="large">
				<CardSection>
					<Grid>
						<GridContainer size="12 6@md 5@lg">
							<Images height="400px" width="100%" image="theme-sd/services7.jpg" />
						</GridContainer>
						<GridContainer size="12 6@md 7@lg" align="middle">
							<Stack direction="column" space="3" align="left">
								<Heading size="3" color="primary">1. Ultimate Package</Heading>
								<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Paragraph>
							</Stack>
						</GridContainer>
					</Grid>
				</CardSection>
			</Card>
			<Card color="secondary" size="large">
				<CardSection>
					<Grid>
						<GridContainer size="12 6@md 7@lg" align="middle">
							<Stack direction="column" space="3" align="left">
								<Heading size="3" color="primary">2. Mini Getaway Package</Heading>
								<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Paragraph>
							</Stack>
						</GridContainer>
						<GridContainer size="12 6@md 5@lg">
							<Images height="400px" width="100%" image="theme-sd/services6.jpg" />
						</GridContainer>
					</Grid>
				</CardSection>
			</Card>
			<Card size="large">
				<CardSection>
					<Grid>
						<GridContainer size="12 6@md 5@lg">
							<Images height="400px" width="100%" image="theme-sd/services5.jpg" />
						</GridContainer>
						<GridContainer size="12 6@md 7@lg" align="middle">
							<Stack direction="column" space="3" align="left">
								<Heading size="3" color="primary">3. Spa Sampler Package</Heading>
								<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Paragraph>
							</Stack>
						</GridContainer>
					</Grid>
				</CardSection>
			</Card>
		</Section>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/projects/sedona/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
}

</script>

<style lang="scss">
</style>

<template>
	<Page>
		<Header>
			<Stack justify="spaceBetween" align="center">
				<Link display="inline" url="/sedona">
					<Images width="286px" height="110px" image="theme-sd/logo.png" />
				</Link>
				<Stack space="3" justify="center" width="auto" visibility="hide show@md">
					<Link text="Home" display="inline" url="/sedona" />
					<Link text="About" display="inline" url="/sedona/about" />
					<Link text="Treatments" display="inline" url="/sedona/treatments" />
					<Link text="Services" display="inline" url="/sedona/services" />
					<Link text="Blog" display="inline" url="/sedona/blog" />
					<Link text="Contact" display="inline" url="/sedona/contact" />
				</Stack>
				<Stack space="3" justify="center" width="auto" visibility="show hide@md" v-click-outside="setClose">
					<Link @click.native="setOpen()" display="inline" url="">
						<Icon icon="menu" color="dark" size="large" v-if="!isOpen" />
						<Icon icon="menu_open" color="dark" size="large" v-if="isOpen" />
					</Link>
					<Dropdown :open="isOpen">
						<Stack direction="column">
							<Button label="Home" color="primary" url="/sedona" />
							<Button label="About" color="primary" url="/sedona/about" />
							<Button label="Treatments" color="primary" url="/sedona/treatments" />
							<Button label="Services" color="primary" url="/sedona/services" />
							<Button label="Blog" color="primary" url="/sedona/blog" />
							<Button label="Contact" color="primary" url="/sedona/contact" />
						</Stack>
					</Dropdown>
				</Stack>
			</Stack>
		</Header>
		<Body>
			<slot></slot>
		</Body>
		<Footer>
			<Stack visibility="hide show@sm">
				<Images height="190px" width="12.5%" image="theme-sd/social5.jpg" />
				<Images height="190px" width="12.5%" image="theme-sd/social8.jpg" />
				<Images height="190px" width="12.5%" image="theme-sd/social3.jpg" />
				<Images height="190px" width="12.5%" image="theme-sd/social4.jpg" />
				<Images height="190px" width="12.5%" image="theme-sd/social1.jpg" />
				<Images height="190px" width="12.5%" image="theme-sd/social6.jpg" />
				<Images height="190px" width="12.5%" image="theme-sd/social7.jpg" />
				<Images height="190px" width="12.5%" image="theme-sd/social2.jpg" />
			</Stack>
			<Card color="tertiary" size="large">
				<CardSection>
					<Grid>
						<GridContainer size="2"></GridContainer>
						<GridContainer size="8">
							<Stack direction="column" space="3">
								<Heading size="1" align="center" whiteout>don't miss a beat!</Heading>
								<Heading size="4" align="center" whiteout>SIGN UP FOR OUR NEWSLETTERS TO GET THE LATEST!</Heading>
								<Stack>
									<Form>
										<FormContainer size="3">							
											<Input label="First Name" />
										</FormContainer>
										<FormContainer size="3">			
											<Input label="Last Name" />
										</FormContainer>
										<FormContainer size="4">			
											<Input label="Email Address" />
										</FormContainer>
										<FormContainer size="2" align="middle">		
											<Button label="Submit" color="dark" size="large" />
										</FormContainer>
									</Form>
								</Stack>
								<Stack space="2" justify="center" visibility="hide show@md">
									<Link text="Home" display="inline" url="/sedona" color="white" />
									<Paragraph whiteout>|</Paragraph>
									<Link text="About" display="inline" url="/sedona/about" color="white" />
									<Paragraph whiteout>|</Paragraph>
									<Link text="Treatments" display="inline" url="/sedona/treatments" color="white" />
									<Paragraph whiteout>|</Paragraph>
									<Link text="Services" display="inline" url="/sedona/services" color="white" />
									<Paragraph whiteout>|</Paragraph>
									<Link text="Blog" display="inline" url="/sedona/blog" color="white" />
									<Paragraph whiteout>|</Paragraph>
									<Link text="Contact" display="inline" url="/sedona/contact" color="white" />
								</Stack>
								<Stack space="2" justify="center">
									<Icon icon="thumb_up" background="light" size="medium" color="tertiary" />
									<Icon icon="favorite_outline" background="light" size="medium" color="tertiary" />
									<Icon icon="email" background="light" size="medium" color="tertiary" />
								</Stack>
							</Stack>
						</GridContainer>
						<GridContainer size="2"></GridContainer>
					</Grid>
					
				</CardSection>
			</Card>
			<Card color="dark" size="small">
				<CardSection>
					<Paragraph align="center" whiteout margin="none" micro>2022 SEDONA SPA AND WELLNESS CENTER © | WEBSITE DESIGNED BY ANNA ROBBINS</Paragraph>
				</CardSection>
			</Card>
		</Footer>
	</Page>
</template>

<script>
export default {
  data() {
    return {
			isOpen: false,
		}
  },
	methods: {
		setOpen(){
			this.isOpen = !this.isOpen;
		},
    setClose() {
      this.isOpen = false
    },
	}
}
</script>

<style lang="scss" scoped>

</style>